import { Link } from "gatsby"
import React from "react"

export default function PostTags({ tags }) {
  return (
    <div className="post-tags">
      {tags.map(tag => (
        <Link
          to={`/tags/${tag.slug}/`}
          key={tag.slug}
          title={`Ver artículos sobre ${tag.name}`}
        >
          {tag.name}
        </Link>
      ))}
    </div>
  )
}
